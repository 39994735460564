/* eslint-disable react/display-name */
import React, { useContext } from "react";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import ImageTextBlock from "../components/ImageTextBlock";
import RichTextImage from "../components/RichTextImage";
import RichTextMultipleImage from "../components/RichTextMultipleImage";
import FAQBlock, { FaqItem } from "../components/FAQBlock";
import CallOut from "../components/CallOut";
import SocialWall, { SocialWallInterface } from "../components/SocialWall";
import { ImageInterface, SanityColorList, Slug, SanityCtaBlock } from "../types/SanityTypes";
import { Link } from "../components/Link";
import ExternalLink from "../components/ExternalLink";
import TableBlock, { TableInterface } from "../components/TableBlock";
import ImageTextBlockIcon from "../components/ImageTextBlockIcon";
import FromToBlock from "../components/FromToBlock";
import { LocalizedContext } from "../services/LocalizedContextService";
import { QuoteIcon } from "../images/icons/quoteIcon";
import CtaBlock from "../components/CtaBlock";

type MultipleImageNode = {
  _key: string;
  _type: string;
  picture: ReadonlyArray<ImageInterface>;
  largeImage: string;
  smallImage: string;
};

type TextNode = {
  _key: string;
  _type: string;
  text: string;
};

type ImageTextNode = {
  image: ImageInterface;
  text: readonly Record<string, unknown>[];
  textPosition: string;
  imageType: boolean;
};

type FAQBlockNode = {
  faqItems: ReadonlyArray<FaqItem>;
  heading?: string;
};

type RichImageNode = {
  image: ImageInterface;
  imageType: {
    _ref?: string;
    name?: string;
  };
  _type: string;
};

type SocialWallNode = SocialWallInterface;

type TableNode = TableInterface;

type BlockProps = {
  children: ReadonlyArray<string>;
  node: NodeProps;
  options: Record<string, unknown>;
};

type NodeProps = {
  style: string;
  _key: string;
  _type: string;
  children: {
    text: string;
    _key: string;
    _type: string;
    marks: ReadonlyArray<string>;
  }[];
};

type ParentSection = Slug & {
  _id: string;
  name: string;
  main: {
    sectionColor: SanityColorList;
  };
  parentSection?: Slug & {
    name: string;
    main: {
      sectionColor: SanityColorList;
    };
  };
};

type MarkProps = {
  children: ReadonlyArray<string>;
  mark: {
    _key: string;
    _type: string;
    reference?: Record<string, unknown>;
    href?: string;
    palleteColorList?: {
      bgColor: {
        title: string;
        value: string;
      };
    };
    align?: string;
    textColor?: {
      bgColor: {
        title: string;
        value: string;
      };
    };
    textSize?: string;
  };
  markKey: string;
  _key: string;
  _type: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderCenterText = (props: { children?: readonly string[]; node: any; options?: Record<string, unknown> }) => {
  return props.node.children[0].marks?.includes("center") ? "centerArticleText" : undefined;
};

export const RichTextSerializers = (pageSlug?: string, colorTheme?: string) => {
  const { sanityProductLandingPage } = useContext(LocalizedContext);

  return {
    types: {
      callOut: ({ node }: { node: TextNode }) => {
        return <CallOut text={node.text} backgroundColor={colorTheme as string} />;
      },
      imageBlock: ({ node }: { node: RichImageNode }) => {
        return (
          <RichTextImage
            data={node.image}
            type={node.imageType.name ? (node.imageType.name as string) : (node.imageType._ref as string)}
          />
        );
      },
      imageTextBlock: ({ node }: { node: ImageTextNode }) => {
        return <ImageTextBlock data={node} />;
      },
      multipleImage: ({ node }: { node: MultipleImageNode }) => {
        return <RichTextMultipleImage data={node} />;
      },
      faqs: ({ node }: { node: FAQBlockNode }) => {
        return <FAQBlock faqItems={node.faqItems} heading={node.heading} />;
      },
      faqBlock: ({ node }: { node: FAQBlockNode }) => {
        return <FAQBlock faqItems={node.faqItems} heading={node.heading} />;
      },
      miappi: ({ node }: { node: SocialWallNode }) => {
        return <SocialWall folder={node.folder} headline={node.headline} socialLink={node.socialLink} />;
      },
      table: ({ node }: { node: TableNode }) => {
        return <TableBlock data={node} />;
      },
      block: (props: BlockProps) => {
        const { style = "normal" } = props.node;

        if (style === "blockquote") {
          return (
            <blockquote>
              <p>
                {props.children.map((text, index) => {
                  return <span key={text + index}>{text}</span>;
                })}
              </p>
            </blockquote>
          );
        }
        if (style === "normal") {
          return (
            <p className={renderCenterText(props)}>
              {props.children.map((text, index) => {
                return <span key={text + index}>{text}</span>;
              })}
            </p>
          );
        }

        return BlockContent.defaultSerializers.types.block(props);
      },
      imageTextBlockIcon: (props: any) => {
        return <ImageTextBlockIcon {...props.node} />;
      },
      imageTextCta: (props: any) => {
        return (
          <div className={`text-block-cta ${props.node?.align ? "align-center" : ""}`}>
            {props.node?.ctaList && props.node?.ctaList.length
              ? props.node?.ctaList.map((item: SanityCtaBlock, index: number) => (
                  <CtaBlock key={index} ctaBlock={item} ctaLabel={item.ctaLabel} />
                ))
              : ""}
          </div>
        );
      },
      fromToBlock: (props: any) => {
        return <FromToBlock {...props.node} />;
      }
    },
    marks: {
      colorlist: (props: MarkProps) => {
        if (props.mark._type === "colorlist") {
          props.children.map((text, index) => {
            return <span key={text + index}>{text}</span>;
          });
        }
        return null;
      },
      color: (props: MarkProps) => {
        return props.children.map((text, index) => {
          return (
            <span className="heart-color" key={text + index}>
              {text}
            </span>
          );
        });
      },
      center: (props: MarkProps) => {
        if (props.markKey === "center") {
          return props.children.map((text, index) => {
            return <span key={text + index}>{text}</span>;
          });
        }
        return null;
      },
      sup: (props: MarkProps) => {
        if (props.markKey === "sup") {
          return props.children.map((text, index) => {
            return <sup key={text + index}>{text}</sup>;
          });
        }
        return null;
      },
      quote: (props: MarkProps) => {
        if (props.markKey === "quote") {
          return props.children.map((text, index) => {
            return (
              <div className="quote__wrapper" key={text + index}>
                <span key={text + index}>{text}</span>
              </div>
            );
          });
        }
        return null;
      },
      internalLink: (props: MarkProps) => {
        if (props.mark._type === "internalLink") {
          const parentSection = props.mark.reference?.parentSection as ParentSection;
          const parentpath = `/${
            props.mark.reference?._type === "productV2" || props.mark.reference?._type === "productLinePage"
              ? sanityProductLandingPage?.slug.current
              : parentSection?.parentSection
              ? `${parentSection?.parentSection.slug.current}/${parentSection?.slug.current}`
              : parentSection?.slug.current
          }`;
          const currentPath = `/${(props.mark.reference?.slug as Record<string, unknown>)?.current}`;

          return props.children.map((text, index) => {
            return (
              <Link
                className="rx-internal-link"
                _id={props.mark.reference?._id as string}
                to={`${parentpath}${currentPath}`}
                key={text + index}
              >
                {text}
              </Link>
            );
          });
        }
      },
      link: (props: MarkProps) => {
        if (props.mark._type === "link") {
          return props.children.map((text, index) => {
            return (
              <React.Fragment key={(props?.mark?.href as string) + index}>
                <ExternalLink
                  hasIcon={false}
                  link={props.mark.href as string}
                  name={text}
                  noopener={true}
                  nofollow={false}
                />
              </React.Fragment>
            );
          });
        }
      },
      textColor: (props: MarkProps) => {
        const { palleteColorList } = props.mark;
        const textClassName = palleteColorList?.bgColor?.title || "default";
        return <span className={`text__color text__color-${textClassName}`}>{props.children}</span>;
      },
      styling: (props: MarkProps) => {
        const { textColor, align = null, textSize } = props.mark;
        const colorClassName = textColor?.bgColor?.title || "default";
        const textSizeClassName = textSize || "default";

        const validTags = ["h1", "h2", "h3", "h4", "h5", "h6"];
        if (textSize && validTags.includes(textSize)) {
          const Tag = textSize as keyof JSX.IntrinsicElements;
          return (
            <Tag
              className={`text__wrapper text__color-${colorClassName} ${
                align ? "text__align text__align-" + align : ""
              } text__size-${textSizeClassName}`}
            >
              {props.children}
            </Tag>
          );
        }

        return (
          <span
            className={`text__wrapper text__color-${colorClassName} ${
              align ? "text__align text__align-" + align : ""
            } ${textSize ? "text__size text__size-" + textSizeClassName : ""}`}
          >
            {props.children}
          </span>
        );
      }
    }
  };
};
